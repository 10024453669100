/**
 * Function to return the utm variables from the passed URL
 * @param url
 * @returns {Array}
 */
const getUTMParams = (
  url: string | undefined | null
): Record<string, string | null> => {
  if (url === undefined || url === null || typeof url !== "string") {
    return {}
  }

  if (url === "") {
    return {}
  }

  try {
    console.log(url)
    const urlObj = new URL(url)

    const queryString = urlObj.search

    const params = new URLSearchParams(queryString)

    return {
      utm_source: params.get("utm_source"),
      utm_medium: params.get("utm_medium"),
      utm_campaign: params.get("utm_campaign"),
      utm_term: params.get("utm_term"),
      utm_content: params.get("utm_content")
    }
  } catch (e) {
    return {}
  }
}

module.exports.getUTMParams = getUTMParams
