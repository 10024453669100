import { getLocaleLanguageCode } from "../utilities/utilities"
import getValueOrEmptyString from "./getValueOrEmptyString"
import { getPagePerimeter } from "./segmentMappings"

const getPageData = (locale, pageValues, slug, path) => {
  const languageCode = getLocaleLanguageCode(locale)
  const { pagePerimeter, pagePublication, pageFeature } = pageValues

  let _pagePerimeter = pagePerimeter
  if (!_pagePerimeter) {
    _pagePerimeter = getPagePerimeter(slug, path)
  }

  return `window.__agicap_page_data = {
    country_code: "${getValueOrEmptyString(languageCode)}",
    page_perimeter: "${getValueOrEmptyString(_pagePerimeter)}",
    page_publication: "${getValueOrEmptyString(pagePublication)}",
    page_feature: "${getValueOrEmptyString(pageFeature)}",
    page_path: "${getValueOrEmptyString(path)}",
    host: "${getValueOrEmptyString(process.env.GATSBY_HOST)}",
    site_env: "${getValueOrEmptyString(process.env.GATSBY_SITE_ENV)}"
  }`
}

export default getPageData
