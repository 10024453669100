import _ from "lodash"
import { useEffect } from "react"
import { getPageSize } from "./getPageSize"
import getDeviceType from "../utilities/getDeviceType"
import { getUTMParams } from "./utilities"
import isBrowser from "../utilities/isBrowser"

export const usePageTracking = extraData => {
  let pageTrackTimeout = null

  let utmData = {}
  if (isBrowser()) {
    utmData = getUTMParams(_.get(window, "location.href", ""))
  }

  const extraDataWithUTMParams = {
    ...utmData,
    ...extraData
  }

  const segment = segmentPageTracker(extraDataWithUTMParams)
  const gtm = gtmPageTracker(extraDataWithUTMParams)

  const clearPageTrackTimeout = () => {
    if (pageTrackTimeout) {
      clearTimeout(pageTrackTimeout)
      segment.clearPageTrackTimeout()
      gtm.clearPageTrackTimeout()
    }
  }

  const trackPageView = () => {
    if (window && window.__agicap_page_data) {
      segment.trackPageView(window.__agicap_page_data)
      gtm.trackPageView(window.__agicap_page_data)
    } else {
      pageTrackTimeout = setTimeout(trackPageView, 100)
    }
  }

  useEffect(() => {
    trackPageView()
    return () => {
      clearPageTrackTimeout()
    }
  }, [])
}

const segmentPageTracker = extraData => {
  let pageTrackTimeout = null

  const clearPageTrackTimeout = () => {
    if (pageTrackTimeout) {
      clearTimeout(pageTrackTimeout)
    }
  }

  const trackPageViewEvent = (pageData, extraData) => {
    if (window && window.analytics && window.analytics.page) {
      const pageSize = getPageSize()
      const pageTitle = document.title

      let trackData = {
        name: document.title,
        path: window.location.pathname,
        referrer: document.referrer,
        search: document.location.search,
        title: pageTitle,
        url: document.URL,
        page_size: pageSize,
        page_perimeter: pageData.page_perimeter,
        page_publication: pageData.page_publication,
        page_feature: pageData.page_feature,
        page_country: pageData.country_code
      }

      if (extraData) {
        trackData = {
          ...extraData,
          ...trackData
        }
      }

      window.analytics.page(pageTitle, trackData)
    } else {
      pageTrackTimeout = setTimeout(() => {
        trackPageViewEvent(pageData, extraData)
      }, 100)
    }
  }

  return {
    clearPageTrackTimeout,
    trackPageView: pageData => {
      trackPageViewEvent(pageData, extraData)
    }
  }
}

const gtmPageTracker = extraData => {
  const clearPageTrackTimeout = () => {}

  const trackPageViewEvent = (pageData, extraData) => {
    const dataToPush = {
      event: "page_view",
      envDevice: getDeviceType(),
      envLanguage: document.documentElement.lang,
      envWork: pageData.site_env,
      envTemplate: pageData.page_perimeter,
      ...extraData
    }
    const dataLayer = window.dataLayer || []
    dataLayer.push(dataToPush)
  }

  return {
    clearPageTrackTimeout,
    trackPageView: pageData => {
      trackPageViewEvent(pageData, extraData)
    }
  }
}
